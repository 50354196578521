// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '../node_modules/bootstrap/scss/_functions';

// 2. Include any default variable overrides here
// Things to know on theme colors:
// 1. Your colors in main theme will change only for each color spelled out.
// 2. Colors not set for borders.

@import '../node_modules/bootstrap/scss/_variables';

$primary: $purple;
// $secondary: #ff9914;
$success: #69dc9e;
$info: #90c290;
$danger: #ff0044;
$light: #e4f0f5;
$dark: #0b2027;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $secondary,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'white': $white,
);

$fontstack1: 'Open Sans', sans-serif;
$fontstack2: 'Poppins', sans-serif;
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop(
  $utilities-colors,
  rgba-css-var,
  '$key',
  'text'
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

$border-width: 3px;
$border-color: $purple-300;

@import '~bootstrap/scss/bootstrap.scss';
html {
  box-sizing: border-box;
  position: relative;
}

body {
  overflow-x: hidden;
  font-family: $fontstack2;
}
.mainpage {
  #hero {
    .row {
      .col-7 {
        height: 100vh;
      }
    }
    img {
      max-height: 100vh;
    }
  }
}

h1 {
  font-family: $fontstack1;
  font-weight: 700;
}
h2.projecthead {
  font-family: $fontstack2;
  color: $primary;
}

h2 {
  font-family: $fontstack2;
  color: $purple-300;
}

#about {
  // margin-top: 33vh;
  // margin-bottom: 33vh;
  // padding: 10vh 0;
  position: relative;
  // height: 100vh;
}
#tools {
  background-color: $gray-200;
  h2 {
    color: $primary;
  }
}
.icons {
  width: 100px;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  svg {
    fill: $gray-700;
  }
  p {
    color: $black;
  }
}
#tools {
  // height: 100vh;
}

.blog {
  position: relative;
  margin-top: 56px;
  .card {
    img {
      height: 200px;
      object-fit: cover;
      object-position: center center;
    }
  }
  article img {
    max-height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.bs-badge-spacing {
  margin-right: 1em;
}

code {
  background: $gray-100;
  color: $black;
  font-size: 1.4rem;
  line-height: 1em;
  margin: 1.5em 0 3em;
  max-width: 100%;
  overflow-x: auto;
  padding: 20px;
}

.card:hover {
  img {
    background-color: red;

    overflow: hidden;
  }
}
.border-start {
  border-left: 10px solid $primary;
}

#contact {
  // padding: 10vh 0;
  // height: 33vh;
}
.work {
  margin-top: 64px;
  ul {
    li {
      list-style-type: none;
    }
  }
  .callouts {
    color: $purple-300;
  }
}
